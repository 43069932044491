/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { atualizarSaldo } from '../../../store/modules/parceiro/actions';
import api from '../../../services/API';
import './styles.css';
import { Link } from 'react-router-dom';
import Pagination from 'rc-pagination';
import 'rc-pagination/assets/index.css';

const styles = {
  categoriasSidebar: {
    backgroundColor: '#f8f9fa',
    padding: '20px',
    borderRadius: '8px',
    marginTop: '20px'
  },
  listaCategorias: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px'
  },
  categoriaBtn: {
    background: 'none',
    border: 'none',
    textAlign: 'left',
    padding: '8px 12px',
    borderRadius: '4px',
    cursor: 'pointer',
    transition: 'all 0.2s ease',
    '&:hover': {
      backgroundColor: '#e9ecef'
    }
  },
  categoriaActive: {
    backgroundColor: '#007bff',
    color: 'white',
    '&:hover': {
      backgroundColor: '#0056b3'
    }
  }
};

export default function PremiosLoja(props) {
  const [premios, setPremios] = useState([]);
  const [total, setTotal] = useState(0);
  const [pagina, setPagina] = useState(1);
  const [limite, setLimite] = useState(12);
  const [carregando, setCarregando] = useState(false);
  const [busca, setBusca] = useState('');
  const [categoriaSelecionada, setCategoriaSelecionada] = useState('');
  const [categoriasUnicas, setCategoriasUnicas] = useState([]);

  const dispatch = useDispatch();
  const parceiroRedux = useSelector((state) => state.parceiro);

  const buscarPremios = useCallback(async () => {
    try {
      setCarregando(true);
      
      const params = {
        pagina,
        limite,
        ativo: 1
      };

      if (busca) {
        params.busca = busca;
      }

      if (categoriaSelecionada) {
        params.categoria = categoriaSelecionada;
      }

      const resultado = await api.get('/api/premios', { params });
      
      if (resultado?.data?.premios) {
        setPremios(resultado.data.premios);
        setTotal(resultado.data.total);
      } else {
        setPremios([]);
        setTotal(0);
      }
    } catch (error) {
      console.error('Erro ao buscar prêmios:', error);
      setPremios([]);
      setTotal(0);
    } finally {
      setCarregando(false);
    }
  }, [pagina, limite, busca, categoriaSelecionada]);

  const buscarCategorias = useCallback(async () => {
    try {
      const resultado = await api.get('/api/premios/categorias-unicas');
      if (resultado?.data?.categorias) {
        setCategoriasUnicas(resultado.data.categorias);
      } else {
        setCategoriasUnicas([]);
      }
    } catch (error) {
      console.error('Erro ao buscar categorias:', error);
      setCategoriasUnicas([]);
    }
  }, []);

  const showDropdown = () => {
    document.getElementById("myDropdown").classList.toggle("show");
  };

  const openNav = () => {
    document.getElementById("mySidenav").style.width = "250px";
  };

  const closeNav = () => {
    document.getElementById("mySidenav").style.width = "0";
  };

  const handleSearch = (e) => {
    if (e.key === 'Enter') {
      handleSearchClick(e);
    }
  };

  const handleSearchClick = async (e) => {
    e.preventDefault();
    setPagina(1);
    await buscarPremios();
  };

  // Efeito inicial
  useEffect(() => {
    const inicializarDados = async () => {
      try {
        await Promise.all([
          buscarPremios(),
          buscarCategorias()
        ]);
      } catch (error) {
        console.error('Erro ao inicializar dados:', error);
      }
    };

    inicializarDados();
  }, [buscarPremios, buscarCategorias]);

  return (
    <Container fluid>
      <div className="d-flex" style={{ maxWidth: '1400px', margin: '0 auto', padding: '0 15px' }}>
        <div style={{ width: '250px', marginRight: '30px', position: 'sticky', top: '20px', height: 'fit-content' }}>
          <div className="container-busca-dois">
            <h5 className="mb-3">CATEGORIAS</h5>
            <div className="w-100 container-secaoDropdown-dois">
              <div className="secaoDropdown-dois w-100">
                <div className="categorias-lista">
                  <button
                    type="button"
                    className={`categoria-btn ${!categoriaSelecionada ? 'active' : ''}`}
                    onClick={() => {
                      setCategoriaSelecionada('');
                      setPagina(1);
                      buscarPremios();
                    }}
                  >
                    Todas
                  </button>
                  {categoriasUnicas.map((categoria) => (
                    <button
                      key={categoria}
                      type="button"
                      className={`categoria-btn ${categoriaSelecionada === categoria ? 'active' : ''}`}
                      onClick={() => {
                        setCategoriaSelecionada(categoria);
                        setPagina(1);
                        buscarPremios();
                      }}
                    >
                      {categoria}
                    </button>
                  ))}
                </div>
              </div>
            </div>
            <hr className="hr-loja mt-0" />
          </div>
        </div>

        <div className="flex-grow-1">
          <div className="mt-3 align-self-start justify-content-center sticky-search">
            <div id="container-busca-geral">
              <div className="d-flex flex-column justify-content-between align-items-center container-busca">
                <h5 className="mb-3">BUSCAR PRODUTO</h5>
                <div className="d-flex flex-row align-items-center justify-content-start align-self-start mb-4 w-100 container-secaoDropdown">
                  <div className="secaoDropdown w-100">
                    <input
                      value={busca}
                      onChange={(e) => setBusca(e.target.value)}
                      onKeyUp={handleSearch}
                      className="input-busca"
                      placeholder="Digite a palavra chave"
                    />
                    <button
                      className="btn-pesquisa mt-0"
                      type="submit"
                      onClick={handleSearchClick}
                    >
                      BUSCAR
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <hr className="hr-loja-desktop mt-0" />
          </div>

          <Row>
            {carregando ? (
              <Col xs={12}>
                <h6>Carregando...</h6>
              </Col>
            ) : premios.length === 0 ? (
              <Col xs={12}>
                <h6>Nenhum produto encontrado.</h6>
              </Col>
            ) : (
              <Row>
                {premios.map((premio) => {
                  return (
                    <Col
                      key={premio.id}
                      id={premio.id}
                      name="premio"
                      xs={12}
                      md={6}
                      lg={3}
                      className="mt-5"
                    >
                      <div className="d-flex flex-column justify-content-center align-items-center text-center">
                        <Link to={`/premio/${premio.id}`}>
                          <img
                            alt={premio.nome}
                            className="w-100"
                            src={premio.diretorioFoto}
                          />
                        </Link>

                        <span className="card-premio-nome">{premio.nome}</span>
                        <span>
                          <b>
                            {Number(premio?.pontos).toLocaleString('pt-BR')} pontos
                          </b>
                        </span>
                        <Link to={`/premio/${premio.id}`}>
                          <button type="button" className="btn-resgatar mt-1">RESGATAR</button>
                        </Link>
                      </div>
                    </Col>
                  );
                })}
              </Row>
            )}
          </Row>
          <Row className="mt-5 mb-5">
            <Col xs={12} className="d-flex justify-content-center">
              <Pagination
                current={pagina}
                total={total}
                pageSize={limite}
                hideOnSinglePage
                locale="pt_BR"
                onChange={(current) => {
                  setPagina(current);
                  window.scrollTo(0, 0);
                }}
              />
            </Col>
          </Row>
        </div>
      </div>
    </Container>
  );
}
