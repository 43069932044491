import React, { useState, useEffect } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Container } from 'react-bootstrap';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import moment from 'moment';
import api from '../../../services/API';
import sortFunc from '../../../utils/ordernarColunaInt';
const ToSearch = (props) => {
  let input;
  const handleClick = () => {
    props.onSearch(input.value);
  };
  const handleEnterKey = (e) => {
    if (e.key === 'Enter') {
      props.onSearch(input.value);
    }
  };
  return (
    <div className="d-flex justify-content-center align-items-center">
      <input
        className="input-theme busca mr-1"
        ref={(n) => (input = n)}
        type="text"
        onKeyUp={handleEnterKey}
      />
      <button
        className="btn-primario-slim"
        onClick={handleClick}
        style={{ width: 40, height: 40 }}
      >
        <i className="fa fa-search px-2"></i>
      </button>
    </div>
  );
};
export default function PontosExpirados() {
  const [pontosExpirados, setPontosExpirados] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    carregarPontosExpirados();
  }, []);
  async function carregarPontosExpirados() {
    try {
      setLoading(true);
      const response = await api.get('/api/pontuacoes-expiradas');
      setPontosExpirados(response.data || []);
    } catch (error) {
      console.error('Erro ao carregar pontos expirados:', error);
    } finally {
      setLoading(false);
    }
  }
  const columns = [
    {
      dataField: 'Parceiro.nome',
      text: 'Parceiro',
      sort: true,
      formatter: (celula, valor) => valor.Parceiro?.nome || 'N/A',
      style: { textAlign: 'left' },
      headerStyle: { textAlign: 'left' },
    },
    {
      dataField: 'pontos',
      text: 'Pontos',
      sort: true,
      sortFunc: sortFunc,
      formatter: (celula, valor) => Number(valor.pontos).toLocaleString('pt-BR'),
    },
    {
      dataField: 'expiradoEm',
      text: 'Data de Expiração',
      sort: true,
      formatter: (celula, valor) => moment(valor.expiradoEm).format('DD/MM/YYYY HH:mm:ss'),
    },
    {
      dataField: 'reativadoEm',
      text: 'Data de Reativação',
      sort: true,
      formatter: (celula, valor) => valor.reativadoEm ? moment(valor.reativadoEm).format('DD/MM/YYYY HH:mm:ss') : 'N/A',
    },
  ];
  const defaultSorted = [
    {
      dataField: 'expiradoEm',
      order: 'desc',
    },
  ];
  const paginationOptions = {
    sizePerPage: 10,
    showTotal: true,
    sizePerPageList: [
      {
        text: '5',
        value: 5,
      },
      {
        text: '10',
        value: 10,
      },
      {
        text: '50',
        value: 50,
      },
      {
        text: '100',
        value: 100,
      },
      {
        text: 'Todos',
        value: pontosExpirados.length,
      },
    ],
    paginationTotalRenderer: (from, to, size) =>
      size > 0 ? (
        <span className="react-bootstrap-table-pagination-total px-2">
          {from}-{to} de {size} resultados
        </span>
      ) : (
        <span className="react-bootstrap-table-pagination-total px-2">
          Nenhum resultado
        </span>
      ),
  };
  return (
    <Container fluid>
      <h4 className="pt-3 pb-3 f-black">Pontos Expirados</h4>
      <hr className="hr-loja mt-0 mb-4" />
      <ToolkitProvider
        bootstrap4
        keyField="id"
        data={pontosExpirados}
        columns={columns}
        search
      >
        {(props) => (
          <div>
            <div className="d-flex justify-content-end mb-3">
              <ToSearch {...props.searchProps} />
            </div>
            <BootstrapTable
              {...props.baseProps}
              noDataIndication={() =>
                loading ? (
                  <span>
                    Carregando
                    <i className="fas fa-circle-notch fa-spin ml-3"></i>
                  </span>
                ) : (
                  <span>Sem resultados</span>
                )
              }
              classes="table-dark text-center rounded"
              wrapperClasses="rounded"
              bordered={false}
              pagination={paginationFactory(paginationOptions)}
              defaultSorted={defaultSorted}
            />
          </div>
        )}
      </ToolkitProvider>
    </Container>
  );
} 