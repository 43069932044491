import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import api from '../../../../services/API';
import { toast } from 'react-toastify';
import { Form, Input } from '@rocketseat/unform';

export default function ModalEditar(props) {
  const [editando, setEditando] = useState(false);
  const [data, setData] = useState({});
  const [descricao, setDescricao] = useState('');
  const [nome, setNome] = useState('');
  const [codigo, setCodigo] = useState('');
  const [custoTotal, setCustoTotal] = useState('');
  const [tipo, setTipo] = useState('');
  const [foto, setFoto] = useState(null);
  const [preview, setPreview] = useState(null);
  const [status, setStatus] = useState('Procurar...');
  const [categoria, setCategoria] = useState('');

  const alterarFoto = async (e) => {
    if (e.target.files.length > 0) {
      setStatus(`${e.target.files[0].name}`);
      setFoto(e.target.files[0]);
      setPreview(URL.createObjectURL(e.target.files[0]));
    } else {
      setStatus('Procurar...');
      setPreview(null);
    }
  };

  const atualizar = async (formData) => {
    if (!categoria) {
      toast.error('Informe a categoria', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      return;
    }

    setEditando(true);

    const data = new FormData();
    data.append('nome', nome || props.data.nome);
    data.append('descricao', descricao || props.data.descricao);
    data.append('codigo', codigo || props.data.codigo);
    data.append('custoTotal', custoTotal || props.data.custoTotal);
    data.append('tipo', tipo || props.data.tipo);
    data.append('categoria', categoria);
    if (foto) {
      data.append('foto', foto);
    }

    try {
      const response = await api.put(`/api/premios/${props.data.id}`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });


      if (response.status === 200) {
        toast.success('Prêmio atualizado com sucesso!', {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 10000,
        });
        props.onHide();
        props.atualizarPremio(response.data);
      }
    } catch (error) {
      console.error('Erro ao atualizar prêmio:', error);
      toast.error('Erro ao atualizar prêmio', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
    }

    setEditando(false);
  };

  useEffect(() => {
    const carregarDadosPremio = async () => {
      try {
        if (!props.data) {
          return;
        }

        setData(props.data);
        setNome(props.data.nome || '');
        setDescricao(props.data.descricao || '');
        setCodigo(props.data.codigo || '');
        setCustoTotal(props.data.custoTotal || '');
        setTipo(props.data.tipo || 'geral');
        setPreview(props.data.diretorioFoto || null);
        setCategoria(props.data.categoria || '');
      } catch (error) {
        console.error('Erro ao carregar dados do prêmio:', error);
        toast.error('Erro ao carregar dados do prêmio');
      }
    };

    carregarDadosPremio();
  }, [props.data]);

  const limparEstados = () => {
    setData({});
    setDescricao('');
    setNome('');
    setCodigo('');
    setCustoTotal('');
    setTipo('');
    setFoto(null);
    setPreview(null);
    setStatus('Procurar...');
    setCategoria('');
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onExit={limparEstados}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Editar Prêmio
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="mt-3 mb-3">
        <div className="d-flex flex-column align-items-center">
          <Form onSubmit={atualizar} className="w-100">
            <div className="form-group">
              <label htmlFor="codigo">Código</label>
              <Input
                id="codigo"
                name="codigo"
                placeholder="Código"
                value={codigo}
                onChange={(e) => setCodigo(e.target.value)}
                className="form-control"
              />
            </div>

            <div className="form-group">
              <label htmlFor="nome">Nome</label>
              <Input
                id="nome"
                name="nome"
                placeholder="Nome"
                value={nome}
                onChange={(e) => setNome(e.target.value)}
                className="form-control"
              />
            </div>

            <div className="form-group">
              <label htmlFor="descricao">Descrição</label>
              <textarea
                id="descricao"
                name="descricao"
                placeholder="Descrição"
                className="form-control premio-desc"
                value={descricao}
                onChange={(e) => setDescricao(e.target.value)}
              ></textarea>
            </div>

            <div className="form-group">
              <label htmlFor="categoria">Categoria</label>
              <Input
                id="categoria"
                name="categoria"
                placeholder="Categoria"
                value={categoria}
                onChange={(e) => setCategoria(e.target.value)}
                className="form-control"
              />
            </div>

            <div className="form-group">
              <label htmlFor="custoTotal">Custo Total</label>
              <Input
                id="custoTotal"
                name="custoTotal"
                type="number"
                step="0.01"
                placeholder="Custo Total"
                value={custoTotal}
                onChange={(e) => setCustoTotal(e.target.value)}
                className="form-control"
              />
            </div>

            <div className="form-group">
              <label htmlFor="tipo">Tipo</label>
              <select
                id="tipo"
                name="tipo"
                placeholder="Tipo"
                value={tipo}
                onChange={(e) => setTipo(e.target.value)}
                className="form-control"
              >
                <option disabled value="">
                  Selecione o tipo
                </option>
                <option value="geral">Geral</option>
                <option value="bivolt">Bivolt</option>
                <option value="credito">Crédito</option>
                <option value="recarga">Recarga</option>
                <option value="pix">Pix</option>
              </select>
            </div>

            <div className="form-group">
              <label htmlFor="foto">Foto</label>
              <div className="custom-file">
                <input
                  type="file"
                  className="custom-file-input"
                  id="foto"
                  onChange={alterarFoto}
                  accept="image/*"
                />
                <label className="custom-file-label" htmlFor="foto">
                  {status}
                </label>
              </div>
            </div>

            {preview && (
              <div className="preview-container mt-3">
                <img
                  src={preview}
                  alt="Preview"
                  className="img-preview"
                />
              </div>
            )}

            <div className="d-flex justify-content-center mt-4">
              {editando ? (
                <button disabled className="btn btn-success">
                  EDITANDO...
                </button>
              ) : (
                <button className="btn btn-success" type="submit">
                  EDITAR
                </button>
              )}
              <button
                type="button"
                className="btn btn-danger ml-2"
                onClick={props.onHide}
              >
                CANCELAR
              </button>
            </div>
          </Form>
        </div>
      </Modal.Body>
    </Modal>
  );
}
