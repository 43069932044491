import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import { Form, Input } from '@rocketseat/unform';
import { toast } from 'react-toastify';
import api from '../../../services/API';

export default function CadastrarPremio() {
  const [enviando, setEnviando] = useState(false);
  const [descricao, setDescricao] = useState('');
  const [nome, setNome] = useState('');
  const [codigo, setCodigo] = useState('');
  const [custoTotal, setCustoTotal] = useState('');
  const [tipo, setTipo] = useState('');
  const [foto, setFoto] = useState(null);
  const [preview, setPreview] = useState(null);
  const [status, setStatus] = useState('Procurar...');
  const [categoria, setCategoria] = useState('');

  const limparCampos = () => {
    setCodigo('');
    setNome('');
    setDescricao('');
    setTipo('');
    setFoto('');
    setPreview('');
    setCustoTotal('');
    setStatus('Procurar...');
    setCategoria('');
  };

  const alterarFoto = async (e) => {
    if (e.target.files.length > 0) {
      setStatus(`${e.target.files[0].name}`);
      setFoto(e.target.files[0]);
      setPreview(URL.createObjectURL(e.target.files[0]));
    } else {
      setStatus('Procurar...');
      setPreview(null);
    }
  };

  const buscarCodigo = async (element) => {
    try {
      let codigo = element.target.value;
      if (!codigo) return;
      
      const resultado = await api.get(`/api/premios/verificar-codigo?codigo=${codigo}`);
      
      if (resultado?.data?.existe) {
        toast.error('Código já cadastrado', {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 10000,
        });
        setCodigo('');
      }
    } catch (error) {
      console.error('Erro ao verificar código:', error.response?.data || error);
      
      if (error.response?.status === 401) {
        toast.error('Sessão expirada. Por favor, faça login novamente.', {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 10000,
        });
      } else {
        toast.error(error.response?.data?.erro || 'Erro ao verificar código. Tente novamente.', {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 10000,
        });
      }
    }
  };

  const cadastrarPremio = async () => {
    if (!nome) {
      toast.error('Informe o nome', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      return;
    }

    if (!codigo) {
      toast.error('Informe o código', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      return;
    }

    if (!descricao) {
      toast.error('Informe a descrição', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      return;
    }

    if (!custoTotal) {
      toast.error('Informe o custo total', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      return;
    }

    if (!tipo) {
      toast.error('Informe o tipo', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      return;
    }

    if (!foto) {
      toast.error('Selecione uma foto', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      return;
    }

    if (!categoria) {
      toast.error('Informe a categoria', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      return;
    }

    setEnviando(true);

    let formData = new FormData();
    formData.append('nome', nome);
    formData.append('descricao', descricao);
    formData.append('codigo', codigo);
    formData.append('custoTotal', custoTotal);
    formData.append('tipo', tipo === 'geral' ? null : tipo);
    formData.append('foto', foto);
    formData.append('categoria', categoria);

    try {
      const response = await api.post('/api/premios', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.status === 200) {
        toast.success('Prêmio cadastrado com sucesso!', {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 10000,
        });
        limparCampos();
      }
    } catch (error) {
      toast.error('Erro ao cadastrar prêmio', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
    }

    setEnviando(false);
  };

  return (
    <Container fluid>
      <div>
        <h4 className="pt-3 pb-3 f-black">Cadastrar Prêmio</h4>
        <hr className="hr-loja mt-0 mb-5" />
      </div>
      <div className="d-flex flex-column align-items-center">
        <Form onSubmit={cadastrarPremio} className="form">
          <label htmlFor="codigo">Código</label>
          <Input
            id="codigo"
            name="codigo"
            placeholder="Código"
            value={codigo}
            onChange={(e) => setCodigo(e.target.value)}
            onBlur={buscarCodigo}
          />
          <label htmlFor="nome">Nome</label>
          <Input
            id="nome"
            name="nome"
            placeholder="Nome"
            value={nome}
            onChange={(e) => setNome(e.target.value)}
          />
          <label htmlFor="descricao">Descrição</label>
          <textarea
            id="descricao"
            name="descricao"
            placeholder="Descrição"
            className="premio-desc p-2"
            value={descricao}
            onChange={(e) => setDescricao(e.target.value)}
          ></textarea>
          <label htmlFor="categoria">Categoria</label>
          <Input
            id="categoria"
            name="categoria"
            placeholder="Categoria"
            value={categoria}
            onChange={(e) => setCategoria(e.target.value)}
          />
          <label htmlFor="custoTotal">Custo Total</label>
          <Input
            id="custoTotal"
            name="custoTotal"
            type="number"
            step="0.01"
            placeholder="Custo Total"
            value={custoTotal}
            onChange={(e) => setCustoTotal(e.target.value)}
          />
          <label htmlFor="tipo">Tipo</label>
          <select
            id="tipo"
            name="tipo"
            placeholder="Tipo"
            value={tipo}
            onChange={(e) => setTipo(e.target.value)}
          >
            <option disabled value="">
              Selecione o tipo
            </option>
            <option value="geral">Geral</option>
            <option value="bivolt">Bivolt</option>
            <option value="credito">Crédito</option>
            <option value="recarga">Recarga</option>
            <option value="pix">Pix</option>
          </select>
          <label htmlFor="foto">Foto</label>
          <div className="custom-file">
            <input
              type="file"
              className="custom-file-input"
              id="foto"
              onChange={alterarFoto}
              accept="image/*"
            />
            <label className="custom-file-label" htmlFor="foto">
              {status}
            </label>
          </div>
          {preview && (
            <div className="preview-container">
              <img
                src={preview}
                alt="Preview"
                className="img-preview"
              />
            </div>
          )}
          {enviando ? (
            <button disabled className="btn-primario" type="submit">
              CADASTRANDO...
            </button>
          ) : (
            <button className="btn-primario" type="submit">
              CADASTRAR
            </button>
          )}
        </Form>
      </div>
    </Container>
  );
}
